.no-padding {
	padding: 0 !important;
	margin: 0 !important;
}

.padding-right-5{

	padding-right: 5px;
}

.padding-left-5{

	padding-left: 5px;
}

.margin-20 {

	margin-right: 20px;
}

.text-green-template{

	color: #5fb611;
}

.color-gold{

	color: #edb867;
}

.color-white{

	color: white;
}

.color-black{

	color: black;
}

.alpha-40 {
	/* Fallback for web browsers that don't support RGBa */
	background-color: rgb(0, 0, 0);
	/* RGBa with 0.6 opacity */
	background-color: rgba(0, 0, 0, 0.4);
	/* For IE 5.5 - 7*/
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000);
	/* For IE 8*/
	-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000)";
}

.watermark{

	position: absolute;
	bottom: 5px;
	right: 5px;
	opacity: 0.7;
	z-index: 99;
	color: white;
}

.toast {
	opacity: 0.9 !important;
}