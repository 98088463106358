.form-cadastro{

	margin-top: 8px;
	/*border: 1px dashed #00AA00;*/
}

.links-estados a{

	padding: 2px 5px;
	margin-left: 10px;
}

.ribbon h1 {
	background: #666666;
	background: -moz-linear-gradient(top, #666666, #333333);
	background: -webkit-gradient(linear, left top, left bottom, from(#666666), to(#333333));
	padding: 10px 20px;
	margin-left: -35px;
	margin-top: 0;
	position: relative;
	width: 400px;

	-moz-box-shadow: 1px 1px 3px #292929;
	-webkit-box-shadow: 1px 1px 3px #292929;
	box-shadow: 1px 1px 3px #292929;

	color: white;
	text-shadow: 0 1px 0 #b3b3b3;
}

.ribbon .arrow {
	width: 0;
	height: 0;
	line-height: 0;
	border-left: 20px solid transparent;
	border-top: 10px solid #333333;
	top: 100%;
	left: 0;
	position: absolute;
}

.da-dots{

	display: none;
}