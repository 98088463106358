.texto-logo-menu{

	font-size: 15px;
}

.header-fixed-shrink .logo img{

	vertical-align: bottom;
}

.logo img{

	vertical-align: middle;
}